import Content from '../../../content.json';

// PNG images //
import BlueDiamond from '../../assets/images/others/blue_diamond.png';
import BluishDiamond from '../../assets/images/others/bluish_diamond.png';
import WhiteDiamond from '../../assets/images/others/white_diamond.png';

import ArtsiesLogo from '../../assets/images/projects/artsies1.png';
import KMTLogo from '../../assets/images/projects/kmt_logo.png';
import RSLogo from '../../assets/images/projects/rs_logo.png';
import CryptoPodzLogo from '../../assets/images/projects/cp_logo.png';
import CCLogo from '../../assets/images/projects/CC_Logo_New.png';

import bp_phone from './../../assets/images/projects/BP.png'
import rs_phone from './../../assets/images/projects/RS.png'
import kmt_phone from './../../assets/images/projects/KMT1.png'

import artsies_ipad from './../../assets/images/projects/artsies_ipad_slit.png'
import KMTIpad from '../../assets/images/projects/kmt_new_c.png';
import CCIpad from '../../assets/images/projects/CC_ipad.png';
import BPIpad from '../../assets/images/projects/bp_ipad.png';
import RSIpad from '../../assets/images/projects/rs_ipad_c.png';
// PNG images //

// WEBP images //
import BlueDiamond_webp from '../../assets/images/others/blue_diamond.webp';
import BlueishDiamond_webp from '../../assets/images/others/bluish_diamond.webp';
import WhiteDiamond_webp from '../../assets/images/others/white_diamond.webp';

import ArtsiesLogo_webp from '../../assets/images/projects/artsies1.webp';
import KMTLogo_webp from '../../assets/images/projects/kmt_logo.webp';
import RSLogo_webp from '../../assets/images/projects/rs_logo.webp';
import CryptoPodzLogo_webp from '../../assets/images/projects/cp_logo.webp';
import CCLogo_webp from '../../assets/images/projects/CC_Logo_New.webp';

import bp_phone_webp from './../../assets/images/projects/BP.webp';
import rs_phone_webp from './../../assets/images/projects/RS.webp';
import kmt_phone_webp from './../../assets/images/projects/KMT1.webp';

import artsies_ipad_webp from './../../assets/images/projects/artsies_ipad_slit.webp';
import KMTIpad_webp from '../../assets/images/projects/kmt_new_c.webp';
import CCIpad_webp from '../../assets/images/projects/CC_ipad.webp';
import BPIpad_webp from '../../assets/images/projects/bp_ipad.webp';
import RSIpad_webp from '../../assets/images/projects/rs_ipad_c.webp';
// WEBP images //

function support_format_webp(){
	const isBrowser = () => typeof window !== "undefined";
	if(isBrowser()){

	    var elem = document.createElement('canvas');

	    if (!!(elem.getContext && elem.getContext('2d')))
	    {
	        // was able or not to get WebP representation
	        return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
	    }
	    else
	    {
	        // very old browser like IE 8, canvas not supported
	        return false;
	    }

	}
}



export const ProjectsData = [
	{
		"id": "0",
		"name": Content.projectsPage.projectsData["3"].name,
		"logo": support_format_webp() ? KMTLogo_webp : KMTLogo,
		"description": Content.projectsPage.projectsData["3"].description,
		"website": Content.projectsPage.projectsData["3"].website,
		"display_image": support_format_webp() ? KMTIpad_webp : KMTIpad,
		"strip_color": "#8DEEFD",
		"diamond": support_format_webp() ? BlueDiamond_webp : BlueDiamond,
		"phone_logo": support_format_webp() ? kmt_phone_webp : kmt_phone
	},
	{
		"id": "1",
	 	"name": Content.projectsPage.projectsData["2"].name,
	 	"logo": support_format_webp() ? CryptoPodzLogo_webp : CryptoPodzLogo,
	 	"description": Content.projectsPage.projectsData["2"].description,
	 	"website": Content.projectsPage.projectsData["2"].website,
	 	"display_image": support_format_webp() ? BPIpad_webp : BPIpad,
	 	"strip_color": "#FFFFFF",
	 	"diamond": support_format_webp() ? WhiteDiamond_webp : WhiteDiamond,
	 	"phone_logo": support_format_webp() ? bp_phone_webp : bp_phone
	},
	{
		"id": "2",
		"name": Content.projectsPage.projectsData["1"].name,
		"logo": support_format_webp() ? RSLogo_webp : RSLogo,
		"description": Content.projectsPage.projectsData["1"].description,
		"website": Content.projectsPage.projectsData["1"].website,
		"display_image": support_format_webp() ? RSIpad_webp : RSIpad,
		"previous_display_image": "",
		"strip_color": "#FFFFFF",
		"diamond": support_format_webp() ? WhiteDiamond_webp : WhiteDiamond,
		"phone_logo": support_format_webp() ? rs_phone_webp : rs_phone
	},
	{
		"id": "3",
		"name": Content.projectsPage.projectsData["5"].name,
		"logo": support_format_webp() ? ArtsiesLogo_webp : ArtsiesLogo,
		"description": Content.projectsPage.projectsData["5"].description,
		"website": Content.projectsPage.projectsData["5"].website,
		"display_image": support_format_webp() ? artsies_ipad_webp : artsies_ipad,
		"strip_color": "#8DEEFD",
		"diamond": support_format_webp() ? WhiteDiamond_webp : WhiteDiamond,
		"phone_logo": ""
	},
	{
		"id": "4",
		"name": Content.projectsPage.projectsData["4"].name,
		"logo": support_format_webp() ? CCLogo_webp : CCLogo,
		"description": Content.projectsPage.projectsData["4"].description,
		"website": Content.projectsPage.projectsData["4"].website,
		"display_image": support_format_webp() ? CCIpad_webp : CCIpad,
		"strip_color": "#8DEEFD",
		"diamond": support_format_webp() ? WhiteDiamond_webp : WhiteDiamond,
		"phone_logo": ""
	}
]